<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center">  
      <div id="QrCode">
        <figure class="qrcode" v-show="this.$route.name != 'AbsenQr'">
          <qrcode
            height="400px" width="400px;"
            :value="data"
            tag="svg"
            :options="{
              errorCorrectionLevel: 'Q',
            }"
          ></qrcode>
        </figure>

        <v-app-bar :app="this.$route.name == 'AbsenQr' && this.StatusQr != null" flat color="transparent">
          <v-btn text class="text-capitalize" @click="QrBack" v-show="this.$route.name == 'AbsenQr' && this.StatusQr != null">
            <v-icon class="mr-2 ml-n1">mdi-arrow-left</v-icon>
            <span>Kembali</span>
          </v-btn>
        </v-app-bar>

        <div class="text-center mt-n13">
          <div class="text-h5 mb-4" v-text="'Absensi '+this.lokasi.namacabang" v-show="this.$route.name == 'AbsenQr' && this.StatusQr == null"></div>
          <figure class="qrcode" v-show="this.$route.name == 'AbsenQr' && this.StatusQr == null">
            <qrcode
              style="border: 1px solid;"
              height="500px" width="500px"
              :value="datastatic"
              tag="svg"
              :options="{
                errorCorrectionLevel: 'Q',
              }"
            ></qrcode>
          </figure>

          <!-- <v-sheet color="transparent" class="mx-auto text-center" width="500px">
            <div class="text-h5 mb-4" v-text="'Absensi '+this.lokasi.namacabang" v-show="this.$route.name == 'AbsenQr' && this.StatusQr == null"></div>

            <div class="mx-auto" style="width: 250px;" v-show="this.$route.name == 'AbsenQr' && this.StatusQr == null">
              <v-btn @click="QrMasuk" dark depressed color="red darken-4" height="55px" block class="text-capitalize subtitle-1 mb-3 rounded-lg">
                <span>QR Masuk</span>
              </v-btn>

              <v-sheet class="rounded-lg">
                <v-btn @click="QrPulang" depressed outlined color="red darken-4" height="55px" block class="text-capitalize subtitle-1 rounded-lg">
                  <span>QR Pulang</span>
                </v-btn>
              </v-sheet>
            </div>
          </v-sheet> -->
        </div>
      </div>
    </v-row>

    <!-- <v-footer app color="transparent">
      <v-col
        class="text-center"
        cols="12"
      >
        <strong v-show="ShowLabelQRMasuk">QR Masuk</strong>
        <strong v-show="ShowLabelQRPulang">QR Pulang</strong>
      </v-col>
    </v-footer> -->
  </v-container>
</template>

<script>
import api from "@/services/http"; 
export default {
    data(){
        return{
            ShowLabelQRMasuk: false,
            ShowLabelQRPulang: false,
            data:null,
            datastatic:null,
            karyawan:null,
            StatusQr:null,
            Jarak:0,
            lokasi:{
              namacabang:'',
              radius:'',
            },
        }
    },
    mounted(){
      console.log(this.$route)
        // console.log('sz',JSON.parse(localStorage.getItem('karyawan')))
        this.karyawan = JSON.parse(localStorage.getItem('karyawan'))
        if (this.$route.params.kodecabang != undefined) {
          this.getcabang(this.$route.params.kodecabang)
          // this.datastatic = JSON.stringify(this.getQrStatic())
        }else{
          this.getcabang(null)
          // this.data = JSON.stringify(this.getQr())
        }
        // this.getcabang()
        // this.karyawan = JSON.parse(localStorage.getItem('karyawan'))
        // this.data = JSON.stringify(this.getQr())
        // this.datastatic = JSON.stringify(this.getQrStatic())
    },
    methods:{
      getcabang(cabang){
        api.get('/lokasi?token='+this.token,
    // { headers: {"Authorization" : `Bearer ${this.token}`} }
    ).then(
        res=>{
          // this.listlokasi = res.data
          console.log('sk',res.data)
          this.lokasi = res.data.find(f => f.kodecabang == (cabang == null ? this.karyawan.Cabang : cabang))
          this.datastatic = JSON.stringify(this.getQrStatic())
          this.data = JSON.stringify(this.getQr())
          this.Jarak = this.ResultJarak()
          // console.log(this.lokasi)
          // console.log(this.karyawan)
          console.log('ww',this.datastatic)
        })
        
      },
      // getLocationUser(){
      //     var options = {
      //     enableHighAccuracy: true,
      //     timeout: 20000,
      //     maximumAge: 0
      //   };
      //   function success(pos) {
      //     var crd = pos.coords;
      //     // console.log('Latitude :', crd.latitude);
      //     // console.log('Longitude : ', crd.longitude);
      //     sessionStorage.setItem('lat',crd.latitude)
      //     sessionStorage.setItem('long',crd.longitude)
      //     // console.log(`Latitude : ${crd.latitude}`);
      //     // console.log(`Longitude: ${crd.longitude}`);
      //   }
      //   function error(err) {
      //     console.warn(`ERROR(${err.code}): ${err.message}`);
      //   }
      //   navigator.geolocation.watchPosition(success, error, options);
      //   // this.setCordinate()
      //   },
      QrMasuk(){
        let qrcode = document.getElementById('QrCode')
        qrcode.style.marginTop = "50px"
        this.StatusQr = "ScanMasuk"
        this.datastatic = JSON.stringify(this.getQrStatic())
        this.ShowLabelQRMasuk = true
        this.ShowLabelQRPulang = false
      },
      QrPulang(){
        let qrcode = document.getElementById('QrCode')
        qrcode.style.marginTop = "50px"
        this.StatusQr = 'ScanPulang'
        this.datastatic = JSON.stringify(this.getQrStatic())
        this.ShowLabelQRMasuk = false
        this.ShowLabelQRPulang = true
      },
      QrBack(){
        // console.log(this.getJarak(sessionStorage.getItem('lat'),sessionStorage.getItem('long'),this.lokasi.latitude,this.lokasi.longitude))
        this.StatusQr = null
        this.ShowLabelQRMasuk = false
        this.ShowLabelQRPulang = false
      },
      ResultJarak(){
        return this.getJarak(sessionStorage.getItem('lat'),sessionStorage.getItem('long'),this.lokasi.latitude,this.lokasi.longitude)
      },
        getTgl(){
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth()+1;
            let dt = date.getDate();

            if (dt < 10) {
            dt = '0' + dt;
            }
            if (month < 10) {
            month = '0' + month;
            }
            return year+'-'+month+'-'+dt
        },
        getJam(){
            let date = new Date()
            let jam = date.getHours();
            let menit = date.getMinutes()
            return jam+'-'+menit
        },
        getQr(){
          // console.log('aaaa',this.$StatusAbsen)
           let nrk = this.karyawan.NRK
           let site = this.lokasi.namacabang
          //  let radius = this.lokasi.Radius
          //  let tgl = this.getTgl()
        //    let jam = this.getJam()
           let data =  {"NRK":nrk,
                        "Lokasi":site,
                        // "radius":radius,
                        "lat":sessionStorage.getItem('lat'),
                        "long":sessionStorage.getItem('long'),
                        // "Tgl":tgl,
                        "KategoriAbsen":null,
                        "Status":this.$StatusAbsen,}
           return data
        },
        getQrStatic(){
          let site = this.lokasi.namacabang
          let lat = this.lokasi.latitude
          let long = this.lokasi.longitude
          // let radius = this.lokasi.Radius
          // let tgl = this.getTgl()
          let data = {
            "NRK":null,
            // "radius":radius,
            // "Tgl":tgl,
            "lat":lat,
            "long":long,
            "Lokasi":site,
            "KategoriAbsen":null,
            "Status":null
          }
          return data
        },
        getJarak(lat1,lon1,lat2,lon2) {
          function deg2rad(deg) {
            return deg * (Math.PI/180)
          }
          var R = 6371; // Radius of the earth in km
          var KmToMeters = 1000; // Km
          var dLat = deg2rad(lat2-lat1);  // deg2rad below
          var dLon = deg2rad(lon2-lon1); 
          var a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2)
            ; 
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
          var d = R * c * KmToMeters; // Distance in meters
          d = parseInt(d)
          return d;
        }
    }
}
</script>